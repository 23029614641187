const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'http://localhost:8000',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logoLink: '/',
    links: [{ text: '', link: '' }],
  },
  sidebar: {
    forcedNavOrder: [
      '/1-authorization', // add trailing slash if enabled above
      '/2-inviteSpecialist',
      '/3-inviteCompany',
      '/4-addedLocation',
      '/5-addedDevice',
      '/6-patient',
      '/7-inspections',
      '/8-request',
      '/9-services',
      '/10-step',
      '/11-reviews',
      '/12-medicalAppointments',
      '/13-analytics',
      '/14-specialistWorkplaceManagement',
      '/15-videoCommunication',
    ],
    collapsedNav: [
      '/access',
      '/registration',
      '/addedPatient',
      '/qr',
      '/createInspections',
      '/inspectionTracking',
      '/choosingLocationAndStarted',
      '/workWithPatients',
      '/settingsVideoCommunication',
      '/useVideoCommunication',
      '/reviewsSettings',
      '/viewReviews',
    ],
    links: [{ text: 'Автономные системы', link: 'https://auto-sys.su/' }],
    frontLine: false,
    ignoreIndex: true,
  },
  siteMetadata: {
    title: 'Gatsby Gitbook Boilerplate | Hasura',
    description: 'Documentation built with mdx. Powering hasura.io/learn ',
    ogImage: null,
    docsLocation: 'https://github.com/hasura/gatsby-gitbook-boilerplate/tree/master/content',
    favicon: 'https://graphql-engine-cdn.hasura.io/img/hasura_icon_black.svg',
  },
};

module.exports = config;
